/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Vat štai jums ir interfeisinis laiškelis. Man reikėjo paieškot kaip „interfeisas“ išsiverčia (bet žinokitės tai yra sąsaja). Kol kas įspūdis visai šiaip jau nieko. Visai kaip barboros užsakymas šiandien. Gražiai ten tie krepšeliai skraido ir pinigėlius skaičiuoja. Liuksas."), "\n", React.createElement(_components.p, null, "Liuksas dar yra dekompresija, kurios irgi dar neišsiaiškinau ir toliau sėkmingai marinuoju. Bet atradimas paprastas kaip dvi kapeikos: galvoj šiti dalykai yra pirmiausia, ir priklauso, tamsta, grynai nuo tamstos perspektyvos. Perspektyvą čiut pakeitus kažkaip labai staigiai šviesiau pasidaro. Nu čia aišku pats atradimas paprastas, o ne perspektyvos keitimo perspektyva. Nes toji tai sudėtingesnė."), "\n", React.createElement(_components.p, null, "Dar vienas neblogas liuksas yra įrankių atradimas. Arba mokomosios medžiagos. Man kažkaip yra sudėtinga geru atveju dvi kalbas mokantiem paaiškint, kad egzistuoja ne vien vienaskaita ir daugiskaita, bet ir visokių kitokių būna. „Ką čia svaigsti“, sako. Du namu, abudu. Suprasti nenoriva – skaitykiva. Keista detalė, kad kažkaip mokykloj tai aš apie tokius bajerius negirdėjau. Nebent kaip bajerį."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
